//main-container全局样式
.app-container {
  padding: 10px;
  width: 100%;
  height: 100%;
}

.components-container {
  background: white;
  padding: 6px;
  border-radius: 3px;
}
